/** @format */

import React from "react";
import { Routes, Route } from "react-router-dom";

import Home from "./home";
import News from "./news";
import Services from "./services";
import About from "./about";
import Contacts from "./contacts";
import NotFound from "../components/notFound";
import Certificate from "./certificate";

function Root() {
  return (
    <Routes>
      <Route path='/' element={<Home />} />
      <Route path='/about/management' element={<About />} />
      <Route path='/project/item/:id' element={<Services />} />
      <Route path='/contacts' element={<Contacts />} />
      <Route path='/news/item/:id' element={<News />} />
      <Route path='/certificates' element={<Certificate />} />
      <Route path='*' element={<NotFound />} />
    </Routes>
  );
}

export default Root;
