/** @format */

import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";

import "./index.css";
import img1 from "../../images/news1.jpg";
import img2 from "../../images/news2.jpg";
import img3 from "../../images/news3.jpg";
import img4 from "../../images/news4.jpg";
import img5 from "../../images/news5.jpg";
import img6 from "../../images/news6.jpg";
import img7 from "../../images/news7.jpg";
import img8 from "../../images/news8.jpg";
import img9 from "../../images/news9.jpg";
import img10 from "../../images/news10.jpg";
import img11 from "../../images/news11.jpg";
import img12 from "../../images/news12.jpg";
import img13 from "../../images/news13.jpg";
import img14 from "../../images/news14.jpg";
import img15 from "../../images/news15.jpg";
import newsItem1 from "../../images/newsItem1.jpg";
import newsItem2 from "../../images/newsItem2.jpg";

const imageData = [img1, img2, img3, img4, img5, img6, img7, img8, img9, img10, img11, img12, img13, img14, img15];
const newsItemData = [img1, newsItem1, newsItem2];

function News() {
  const params = useParams();
  const [data, setData] = useState({});
  const { t } = useTranslation();
  const { colors } = useSelector((state) => state.theme);

  useEffect(() => {
    const sortData = () => {
      t("news.newsData", { returnObjects: true }).forEach((element) => {
        if (element.id === params.id) {
          setData(element);
        }
      });
    };

    sortData();
  }, [t, params]);

  return (
    <section className='news_page_sections'>
      {params.id === "1" ? (
        <div className='image_item_box'>
          {newsItemData.map((item, index) => (
            <img
              className='image_item_data'
              key={index}
              src={item}
              alt={`image${index}`}
            />
          ))}
        </div>
      ) : (
        <div className='new_image_box'>
          <img src={imageData[params.id - 1]} alt='imageNews' />
        </div>
      )}

      <div
        style={{
          color: colors.text3,
        }}
        className='context_data'>
        <h1>{data.title}</h1>

        <p>{data.descriptions}</p>
      </div>
    </section>
  );
}

export default News;
