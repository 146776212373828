/** @format */

import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { toggleTheme } from "../../redux/actions/themeType";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import MenuIcon from "@mui/icons-material/Menu";
import CloseIcon from "@mui/icons-material/Close";

import clock from "../../images/Edit3.svg";
import facebook from "../../images/facebook.svg";
import instagram from "../../images/insta.svg";
import youtube from "../../images/youtube.svg";
import telegram from "../../images/telegram.svg";
import watsapp from "../../images/watsapp.svg";
import gmail from "../../images/gmail.svg";
import phone from "../../images/phone.svg";
import logo from "../../images/logo1.png";
import { IconButton } from "@mui/material";

const socialMediaLinks = [
  {
    img: facebook,
    link: "/",
  },
  {
    img: instagram,
    link: "https://www.instagram.com/bkpi_loyihachilar/profilecard/?igsh=dWVzOXhsOG5vb25q",
  },
  {
    img: youtube,
    link: "/",
  },
  {
    img: telegram,
    link: "https://t.me/bkpi_uz2020",
  },
  {
    img: watsapp,
    link: "/",
  },
  {
    img: gmail,
    link: "https://mail.google.com/mail/?view=cm&fs=1&to=uzbkpi@gmail.com",
  },
  {
    img: phone,
    link: "/",
  },
];

function Header() {
  const [openModal, setOpenModal] = useState(false);
  const dispatch = useDispatch();
  const { i18n, t } = useTranslation();
  const { colors, theme } = useSelector((state) => state.theme);

  const changeLanguage = (lng) => i18n.changeLanguage(lng);
  const handleToggleTheme = () => dispatch(toggleTheme());

  const renderDropdown = (titleKey, componentKey) => (
    <div
      className='dropdown'
      style={{
        backgroundColor: colors.buttonColor,
        color: colors.buttonText1,
        borderRadius: "5px",
        marginLeft: "5px",
      }}>
      <button
        className='dropbtn'
        style={{
          backgroundColor: colors.buttonColor,
          color: colors.buttonText1,
        }}>
        {t(titleKey)}
        <KeyboardArrowDownIcon />
      </button>
      <div
        className='dropdown-content'
        style={{
          backgroundColor: colors.buttonColor,
          color: colors.buttonText1,
        }}>
        {t(componentKey, { returnObjects: true }).map((item, index) => (
          <Link
            style={{
              color: colors.buttonText1,
              marginTop: 3,
            }}
            key={index}
            to={item.link}>
            {item.title}
          </Link>
        ))}
      </div>
    </div>
  );

  const onClickButton = () => {
    setOpenModal(!openModal);
  };

  return (
    <section
      className='header_sections'
      style={{ background: colors.topHeaderColor }}>
      <div
        className='top_header_section'
        style={{ boxShadow: `0px 1px 10px 1px ${colors.boxShadow}` }}>
        <div className='work_info_box'>
          <img src={clock} alt='work_time_image' className='work_time_image' />
          <h1 className='work_info' style={{ color: colors.text1 }}>
            {t("header.top_navSettings.working_time")}
          </h1>
        </div>
        <div
          className={`social_media_box ${
            openModal ? "open_social_media" : ""
          }`}>
          {socialMediaLinks.map((item, index) => (
            <a style={{
              margin: 0,
              padding: 0
            }} key={index} href={item.link}>
              <img
                src={item.img}
                className='social_media'
                alt={`social_icon_${index}`}
              />
            </a>
          ))}

          <div className='switch-container'>
            <input
              onChange={handleToggleTheme}
              checked={theme === "light"}
              type='checkbox'
              id='switch'
            />
            <label htmlFor='switch'>
              <i className='fas fa-moon'></i>
              <i className='fas fa-sun'></i>
              <span className='ball'></span>
            </label>
          </div>

          <div
            className='dropdown'
            style={{ width: "50px", marginLeft: "10px", zIndex: 1000 }}>
            <button
              className='dropbtn'
              style={{
                backgroundColor: colors.buttonColor,
                color: colors.buttonText1,
              }}>
              {i18n.language === "uz"
                ? "O'z"
                : i18n.language === "ru"
                ? "RU"
                : "EN"}
            </button>
            <div
              className='dropdown-content'
              style={{
                backgroundColor: colors.buttonColor,
                color: colors.buttonText1,
                width: "50px",
                minWidth: "50px",
              }}>
              {["uz", "ru", "en"].map((lng) => (
                <Link
                  style={{
                    color: colors.buttonText1,
                    width: "50px",
                  }}
                  key={lng}
                  onClick={() => changeLanguage(lng)}
                  to='#'>
                  {lng.toUpperCase()}
                </Link>
              ))}
            </div>
          </div>
        </div>
      </div>

      <div
        className='navbat_styles'
        style={{ boxShadow: `0px 1px 10px 1px ${colors.boxShadow}` }}>
        <div className='logo_box'>
          <Link to='/' className='logo_link'>
            <img src={logo} alt='logo' className='logo_header' />
          </Link>
        </div>

        <div
          className={`navbar_element_box ${
            openModal ? "open_navbar" : "close_navbar"
          }`}>
          {renderDropdown(
            "header.navbarLinks.aboutSociety.title",
            "header.navbarLinks.aboutSociety.component"
          )}
          <Link
            to='/about'
            className='nav_element'
            style={{
              backgroundColor: colors.buttonColor,
              color: colors.buttonText1,
            }}>
            {t("header.navbarLinks.ourServices.title")}
          </Link>
          {renderDropdown(
            "header.navbarLinks.management.title",
            "header.navbarLinks.management.component"
          )}
          {renderDropdown(
            "header.navbarLinks.opensourceData.title",
            "header.navbarLinks.opensourceData.component"
          )}
          {renderDropdown(
            "header.navbarLinks.pressService.title",
            "header.navbarLinks.pressService.component"
          )}

          <IconButton
            onClick={onClickButton}
            sx={{
              color: colors.buttonText1,
              bgcolor: colors.buttonColor,
            }}
            className={`open_modal_element ${
              openModal ? "close_icon_open" : "close_icon_close"
            }`}>
            <CloseIcon />
          </IconButton>
        </div>

        <IconButton
          onClick={onClickButton}
          sx={{
            color: colors.buttonText1,
            bgcolor: colors.buttonColor,
          }}
          className={`open_modal_element ${
            openModal ? "menu_icon_open" : "menu_icon_close"
          }`}>
          <MenuIcon />
        </IconButton>
      </div>
    </section>
  );
}

export default Header;
