/** @format */

import React from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";

import CertificateImage from '../../images/certificate.jpg'

function Certificate() {
  const { t } = useTranslation();
  const { colors } = useSelector((state) => state.theme);

  return (
    <section className='services_section'>
      <h2
        style={{
          color: colors.text1,
        }}>
        {t("certificate.title")}
      </h2>

      <p
        style={{
          color: colors.text1,
        }}>
        {t("certificate.description")}
      </p>

      <div style={{
        boxShadow: `0px 2px 5px 2px ${colors.boxShadow}`
      }} className="certificate_box">
        <img src={CertificateImage} alt="certificate_image" />
        <h2>
            {
                t("certificate.certificateData")
            }
        </h2>
      </div>
    </section>
  );
}

export default Certificate;
