/** @format */

import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import "./index.css";

import img1 from "../../images/Picture1.png";
import img2 from "../../images/Picture2.png";
import img3 from "../../images/Picture3.png";
import img4 from "../../images/Picture4.png";
import img5 from "../../images/Picture5.png";
import img6 from "../../images/Picture6.png";
import img7 from "../../images/Picture7.png";
import img8 from "../../images/Picture8.png";
import img9 from "../../images/Picture9.png";
import img10 from "../../images/Picture10.png";

import andijon from "../../images/introBg4.jpg";
import topalang from "../../images/topalang.jpg";
import chorvoq from "../../images/chorvoq.jpg";
import oxangaron from "../../images/oxangaron.jpg";

const imageData = [
  [img1, img2, img3, img7, img10],
  [img1, img2, img3, img4],
  [img1, img2, img3, img4, img5, img6],
  [img1, img2, img3, img7, img8, img9],
];

const dataImages = [topalang, chorvoq, oxangaron, andijon];

function Services() {
  const params = useParams();
  const [data, setData] = useState({});
  const { t } = useTranslation();
  const { colors } = useSelector((state) => state.theme);

  useEffect(() => {
    const sortData = () => {
      t("projectData.projectArray", { returnObjects: true }).forEach(
        (element) => {
          if (element.id === params.id) {
            setData(element);
          }
        }
      );
    };

    sortData();
  }, [t, params]);

  return (
    <section className='news_page_sections'>
      <div
        style={{
          color: colors.text3,
        }}
        className='context_data'>
        <h1>{data?.title}</h1>

        <img className="product_images_item" src={dataImages[params.id - 1]} alt='images' />

        {data?.description?.map((item, index) => (
          <p
            style={{
              marginTop: 10,
            }}
            key={index}>
            {item.title}
          </p>
        ))}
      </div>

      <div className='use_pdoroduct_images'>
        {data?.useProduct?.map((item, index) => (
          <div
            style={{
              background: colors.topHeaderColor,
              boxShadow: `0px 2px 5px 2px ${colors.boxShadow}`,
              marginLeft: index === 0 ? 0 : 15,
              color: colors.text1,
            }}
            className='use_product_img_box'
            key={index}>
            <img src={imageData[params.id - 1][index]} alt={`Image${index}`} />
            <h4>{item.title}</h4>
          </div>
        ))}
      </div>

      <div
        style={{
          color: colors.text1,
        }}
        className='information_use_poroduct'>
        <h3>
          {t("projectData.ordered")} <span>{data?.ordered}</span>
        </h3>
        <h3>
          {t("projectData.location")}: <span>{data?.country}</span>
        </h3>
        <h3>
          {t("projectData.startYear")}: <span>{data?.start}</span>
        </h3>
      </div>
    </section>
  );
}

export default Services;
