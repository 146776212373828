/** @format */

import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import emailjs from "emailjs-com";

import "./styles.css";
import icon1 from "../../images/icon1.svg";
import icon2 from "../../images/icon2.svg";
import icon3 from "../../images/icon3.svg";
import icon4 from "../../images/icon4.svg";
import icon5 from "../../images/icon5.svg";
import icon6 from "../../images/icon6.svg";
import icon7 from "../../images/icon7.svg";
import icon8 from "../../images/icon8.svg";
import icon9 from "../../images/icon9.svg";
import icon10 from "../../images/icon10.svg";
import icon11 from "../../images/icon11.svg";
import icon12 from "../../images/icon12.svg";
import icon13 from "../../images/icon13.svg";
import icon14 from "../../images/icon14.svg";
import icon15 from "../../images/icon15.svg";
import icon16 from "../../images/icon16.svg";
import icon17 from "../../images/icon17.svg";
import icon18 from "../../images/icon18.svg";
import icon19 from "../../images/icon19.svg";
import icon20 from "../../images/icon20.svg";
import icon21 from "../../images/icon21.svg";
import icon22 from "../../images/icon22.svg";
import icon23 from "../../images/icon23.svg";
import icon24 from "../../images/icon24.svg";

import img1 from "../../images/news1.jpg";
import img2 from "../../images/news2.jpg";
import img3 from "../../images/news3.jpg";
import img4 from "../../images/news4.jpg";
import img5 from "../../images/news5.jpg";
import img6 from "../../images/news6.jpg";
import img7 from "../../images/news7.jpg";
import img8 from "../../images/news8.jpg";
import img9 from "../../images/news9.jpg";
import img10 from "../../images/news10.jpg";
import img11 from "../../images/news11.jpg";
import img12 from "../../images/news12.jpg";
import img13 from "../../images/news13.jpg";
import img14 from "../../images/news14.jpg";
import img15 from "../../images/news15.jpg";

import imgLogo1 from "../../images/partnes1.svg";
import imgLogo2 from "../../images/partnes2.svg";
import imgLogo3 from "../../images/partnes3.png";
import imgLogo4 from "../../images/partnes4.svg";

import andijon from "../../images/introBg4.jpg";
import topalang from "../../images/topalang.jpg";
import chorvoq from "../../images/chorvoq.jpg";
import oxangaron from "../../images/oxangaron.jpg";
import aboutImage from "../../images/logo1.png";

const Orb = ({ value, label, index, label2 }) => {
  const [counter, setCounter] = useState(0);
  const { colors } = useSelector((state) => state.theme);

  const data = [
    "fa-solid fa-people-group",
    "fa-solid fa-calendar-days",
    "fa-solid fa-chart-line",
    "fa-solid fa-gear",
  ];

  useEffect(() => {
    let start = 0;
    const duration = 1500;
    const interval = setInterval(() => {
      start += 1;
      setCounter(Math.min(start, value));
      if (start >= value) clearInterval(interval);
    }, duration / value);

    return () => clearInterval(interval);
  }, [value]);

  return (
    <article className='orb'>
      <div
        style={{
          color: colors.text1,
        }}
        className='orb_box'>
        <i className={`${data[index]} intro_icon`}></i>
        <div className='orb_value'>
          {counter} {label2}
        </div>
      </div>
      <div className='orb_label'>{label}</div>
    </article>
  );
};

const dataImages = [topalang, chorvoq, oxangaron, andijon];

const servicesData = [
  icon1,
  icon2,
  icon3,
  icon4,
  icon5,
  icon6,
  icon7,
  icon8,
  icon9,
  icon10,
  icon11,
  icon12,
  icon13,
  icon14,
  icon15,
  icon16,
  icon17,
  icon18,
  icon19,
  icon20,
  icon21,
  icon22,
  icon23,
  icon24,
];

const newImage = [img1, img2, img3, img4, img5, img6, img7, img8, img9, img10, img11, img12, img13, img14, img15];

const imageLogo = [imgLogo1, imgLogo2, imgLogo3, imgLogo4];

function Home() {
  const [formData, setFormData] = useState({
    user_name: "",
    user_phone: "",
    message: "",
  });
  const { t } = useTranslation();
  const { colors } = useSelector((state) => state.theme);

  const handleChange = (e) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value,
    });
  };

  const sendEmail = (e) => {
    e.preventDefault();

    emailjs
      .send(
        "service_avux7m7",
        "template_vvsbqed",
        formData,
        "JlaxV5L_nop4MFCH0" // Your EmailJS user ID (found in your account)
      )
      .then(
        (result) => {
          console.log("Email sent successfully:", result.text);
          alert("Email sent successfully!");
          setFormData({
            user_name: "",
            user_phone: "",
            message: "",
          });
        },
        (error) => {
          console.log("Failed to send email:", error.text);
          alert("Failed to send email.");
        }
      );
  };

  return (
    <>
      <section
        style={{
          color: colors.textWhite,
        }}
        className='intro_sections'>
        <div className='intro_info_box'>
          <h1>{t("intro.headTitle")}</h1>

          <p>{t("intro.descriptionsTitle")}</p>
        </div>

        <div
          style={{
            color: colors.textWhite,
          }}
          className='intro_circle_info'>
          {t("intro.percentData", { returnObjects: true }).map((orb, index) => (
            <Orb
              key={index}
              index={index}
              value={orb.years}
              label={orb.descriptionsPecent}
              label2={orb.yearsTitle}
            />
          ))}
        </div>
      </section>

      <section className='about_section_page'>
        <h1
          style={{
            color: colors.text1,
          }}>
          {t("aboutCompany.title")}
        </h1>
        <div
          className='about_context_box'
          style={{
            color: colors.text3,
          }}>
          <div>
            <p>
              <span>{t("aboutCompany.descriptions1")} </span>
              {t("aboutCompany.descriptions2")}
            </p>
          </div>

          <img src={aboutImage} alt='aboutImage' />
        </div>
      </section>

      <section className='services_section'>
        <h2
          style={{
            color: colors.text1,
          }}>
          {t("service.title")}
        </h2>

        <p
          style={{
            color: colors.text1,
          }}>
          {t("service.descriptions")}
        </p>

        <div className='service_box'>
          {t("service.serviceData", { returnObjects: true }).map(
            (item, index) => (
              <div
                style={{
                  color: colors.text3,
                }}
                key={index}
                className='services_card'>
                <img
                  style={{
                    filter: colors.iconFilter,
                  }}
                  src={servicesData[index]}
                  alt={index}
                />
                <p>{item.title}</p>
              </div>
            )
          )}
        </div>
      </section>

      <section className='services_section'>
        <h2
          style={{
            color: colors.text1,
          }}>
          {t("projectData.title")}
        </h2>

        <p
          style={{
            color: colors.text1,
          }}>
          {t("projectData.description")}
        </p>

        <div className='project_container'>
          {t("projectData.projectArray", { returnObjects: true }).map(
            (item, index) => (
              <Link
                style={{
                  textDecoration: "none",
                  color: colors.text1,
                }}
                to={`/project/item/${item.id}`}
                key={index}>
                <div
                  style={{
                    background: colors.topHeaderColor,
                    boxShadow: `0px 2px 5px 2px ${colors.boxShadow}`,
                  }}
                  className='project_data_box'>
                  <img
                    className='project_images_head'
                    src={dataImages[index]}
                    alt={`image${index}`}
                  />

                  <h3>{item.title}</h3>

                  <p
                    style={{
                      color: colors.text3,
                    }}>
                    {item.description[0].title.substr(0, 150)}
                  </p>
                </div>
              </Link>
            )
          )}
        </div>
      </section>

      <section className='news_section'>
        <h2
          style={{
            color: colors.text1,
          }}>
          {t("news.title")}
        </h2>

        <p
          style={{
            color: colors.text1,
          }}>
          {t("news.descriptions")}
        </p>

        <div className='news_box'>
          {t("news.newsData", { returnObjects: true }).map((item, index) => (
            <div
              style={{
                color: colors.text3,
                background: colors.blurFilter,
              }}
              key={index}
              className='news_container'>
              <img src={newImage[index]} alt={index} />
              <p className='news_date'>{item.date}</p>

              <h3
                style={{
                  color: colors.text1,
                }}>
                {item.title}
              </h3>

              <p>{item.descriptions.substr(0, 200)}...</p>

              <Link
                style={{
                  background: colors.background,
                  color: colors.buttonColor,
                }}
                to={`${item.link}/${item.id}`}>
                {item.linkName}
              </Link>
            </div>
          ))}
        </div>
      </section>

      <section
        style={{
          background: colors.blurFilter,
        }}
        className='maps_section'>
        <h2
          style={{
            color: colors.text1,
          }}>
          {t("contacts.title")}
        </h2>

        <p
          style={{
            color: colors.text1,
          }}>
          {t("contacts.descriptions")}
        </p>

        <div
          style={{
            background: colors.topHeaderColor,
          }}
          className='input_box'>
          <form onSubmit={sendEmail} className='content_box'>
            <h4
              style={{
                color: colors.text3,
              }}>
              {t("contacts.contactsData.contactAbout")}
            </h4>

            <div className='input-group'>
              <input
                style={{
                  background: colors.background,
                  color: colors.text3,
                }}
                type='text'
                required
                name='user_name'
                value={formData.user_name}
                onChange={handleChange}
              />
              <label
                style={{
                  color: colors.text3,
                }}>
                {t("contacts.contactsData.contactsName")}
              </label>
            </div>

            <div className='input-group'>
              <input
                style={{
                  background: colors.background,
                  color: colors.text3,
                }}
                type='text'
                name='user_phone'
                value={formData.user_phone}
                onChange={handleChange}
                required
              />
              <label
                style={{
                  color: colors.text3,
                }}>
                {t("contacts.contactsData.contactsNumber")}
              </label>
            </div>

            <div className='input-group'>
              <textarea
                style={{
                  background: colors.background,
                  color: colors.text3,
                }}
                name='message'
                value={formData.message}
                onChange={handleChange}
                type='text'
                required
              />
              <label
                style={{
                  color: colors.text3,
                }}>
                {t("contacts.contactsData.contactsDescriptions")}
              </label>
            </div>

            <button
              style={{
                background: colors.buttonColor,
                color: "#fff",
              }}
              type='submit'
              className='submit_button'>
              {t("contacts.contactsData.contactsButton")}
            </button>
          </form>

          <iframe
            src='https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2881.8772488213567!2d69.32957067591194!3d41.26475197131571!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x38ae5fdd0efd714f%3A0xe9d97c22dd4cc803!2sSmart%20Solutions%20System!5e1!3m2!1sru!2s!4v1726728394591!5m2!1sru!2s'
            className='maps_container'
            loading='lazy'
            referrerPolicy='no-referrer-when-downgrade'
            title={t("contacts.title")}
          />
        </div>
      </section>

      <section className='news_section'>
        <h2
          style={{
            color: colors.text1,
          }}>
          {t("colleagues.title")}
        </h2>

        <p
          style={{
            color: colors.text1,
          }}>
          {t("colleagues.description")}
        </p>

        <div className='logo_container_parents'>
          {imageLogo.map((item, index) => (
            <img key={index} src={item} alt={`image${index}`} />
          ))}
        </div>
      </section>
    </>
  );
}

export default Home;
