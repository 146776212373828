/** @format */

import React from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import "./index.css";

import team1 from "../../images/team1.jpg";
import team2 from "../../images/team2.png";
import team3 from "../../images/team3.jpg";
import team4 from "../../images/team4.jpg";
import team5 from "../../images/team5.jpg";
import team6 from "../../images/team6.jpg";
import team7 from "../../images/team7.jpg";
import team8 from "../../images/team8.jpg";

const data = [
  {
    name: "Melikboboyev Avazbek",
    images: team1,
  },
  {
    name: "Gaffarov Saidbobir",
    images: team2,
  },
  {
    name: "Buzruqov Baxodir",
    images: team3,
  },
  {
    name: "Zokirov Ilhomjon",
    images: team4,
  },
  {
    name: "Ibrohimov Jamshidbek",
    images: team5,
  },
  {
    name: "Uzoqov Ulug'bek",
    images: team6,
  },
  {
    name: "Savriyev Samrat",
    images: team7,
  },
  {
    name: "Qodirov Dilshodbek",
    images: team8,
  },
];

function About() {
  const { t } = useTranslation();
  const { colors } = useSelector((state) => state.theme);

  return (
    <section className='about_section_page'>
      <div
        className='maps_section team_section_container'>
        <h2
          style={{
            color: colors.text1,
          }}>
          {t("aboutCompany.managment")}
        </h2>
        <p
          style={{
            color: colors.text1,
          }}>
          {t("aboutCompany.teamDescription")}
        </p>

        <div className='team_information_box'>
          {data.map((item, index) => (
            <div
              className='team_box'
              style={{
                marginLeft: 10,
                background: colors.topHeaderColor,
                boxShadow: `0px 2px 5px 2px ${colors.boxShadow}`
              }}
              key={index}>
              <img src={item.images} alt={item.images} />
              <h3
                style={{
                  color: colors.text3,
                }}>
                <span
                  style={{
                    color: colors.text1,
                  }}>
                  {t("aboutCompany.name")}:
                </span>{" "}
                {item.name}
              </h3>
              <p
                style={{
                  color: colors.text3,
                }}>
                <span
                  style={{
                    color: colors.text1,
                  }}>
                  {t("aboutCompany.position")}:
                </span>{" "}
                {
                  t("aboutCompany.teamData", { returnObjects: true })[index]
                    .title
                }
              </p>
            </div>
          ))}
        </div>
      </div>
    </section>
  );
}

export default About;
